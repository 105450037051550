import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text, CircularProgress,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ethers } from "ethers"
import {
  useParams,
} from "react-router-dom";

export const FormRed = () => {
  let [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    setError('');
    setSuccess('');
    submitForm(id);
  }, [id])

  const submitForm = async (input:any) => {
    setInput(input);
    setIsLoading(true);
    try {
      let provider: any;
      provider = new ethers.providers.JsonRpcProvider("https://cloudflare-eth.com");
      const resolver = await provider.getResolver(input);
      if (!resolver) {
        setError("Can't resolve domain or can't find IPFS content record for " + input);
      }
      let result = await resolver.getContentHash();
      if (result === '') {
        setError("Can't resolve domain or can't find IPFS content record for " + input);
      }
      else {
        setSuccess("Resolved " + input + ", redirecting to IPFS Page.");
        window.location.href = result;
      }
      setIsLoading(false);
    } catch (err) {
      setError("Can't resolve domain or can't find IPFS content record.");
      setIsLoading(false);
    }
  }


  const isENSDomain = (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(input) === false)

  return (
    <Flex
      minH={'80vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'}>Resolving Your Web3 ENS Domain</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Decentr will look up the IPFS address in the content field of your ens domain records and create the page that points to the IPFS address.
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <form  >
              <FormControl isInvalid={isENSDomain} isRequired>
                <FormLabel htmlFor="ensdomain" >ENS Domain </FormLabel>
                <Input
                  id="ensdomain"
                  type="text"
                  value={input}
                  isDisabled
                />
              </FormControl>
              <Stack spacing={10}>
                {(error !== '') ? (
                  <Alert
                    status='error'
                    mt='6'
                    fontSize='sm'>
                    <AlertIcon />
                    {error}
              </Alert>
                ) : (
                    <></>
                  )}
                {(success !== '') ? (
                  <Alert
                    status='success'
                    mt='6'
                    fontSize='sm'>
                    <AlertIcon />
                    <AlertDescription maxWidth='sm'>
                    {success}
                </AlertDescription>
                  </Alert>
                ) : (<></>)
                }
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  type="submit"
                  isDisabled
                >
                  {isLoading ? (
                    <CircularProgress isIndeterminate size="24px" color="teal" />
                  ) : (
                      'Generate The Page Now'
                    )}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
